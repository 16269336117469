import axiosInstance from '@/utils/http-client'

const useAuth = () => {
  const getCurrentUser = async () => {
    try {
      const res = await axiosInstance.get('/auth/currentLoginUser')
      return res.data
    } catch (error) {
      console.error('Error while fetching current user', error)
      throw error
    }
  }

  return {
    getCurrentUser,
  }
}

export default useAuth
