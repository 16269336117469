import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '@/context/AuthContextProvider'
import MainLayout from '../layout/MainLayout'

function ProtectedRoute({ children, roles }) {
  const { user } = useContext(AuthContext)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (user && roles) {
      if (
        !user?.platform?.skills
        || (roles?.length > 0
          && !roles.includes(user.platform.skills.toLowerCase()))
      ) {
        return <Navigate to="/" />
      }
    }
  }, [user])
  return <MainLayout>{children}</MainLayout>
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
  roles: PropTypes.array,
}

ProtectedRoute.defaultProps = {
  roles: []
}

export default ProtectedRoute
