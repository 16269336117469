/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const getNewAccessToken = async () => {
  const refreshToken = cookies.get('refreshToken')
  if (!refreshToken) {
    throw new Error('No refresh token available')
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refreshToken`,
      {
        refreshToken,
      }
    )
    if (!res.data.accessToken) {
      throw new Error('No access token available')
    }
    const newAccessToken = res.data.accessToken
    // const newRefreshToken = res.data.refreshToken
    // cookies.set('accessToken', newAccessToken,
    // )
    // cookies.set('refreshToken', newRefreshToken)
    return newAccessToken
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Interceptor to add access token to requests
service.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get('accessToken')
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

// Interceptor to handle 401 errors and refresh token
service.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (!isRefreshing) {
        isRefreshing = true

        try {
          const newAccessToken = await getNewAccessToken()
          processQueue(null, newAccessToken)
          // originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
          return service(originalRequest)
        } catch (err) {
          processQueue(err, null)
          throw err
        } finally {
          isRefreshing = false
        }
      } else {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return service(originalRequest)
          })
          .catch((err) => Promise.reject(err))
      }
    }

    return Promise.reject(error)
  }
)

export default service
export { getNewAccessToken as refreshToken }
