import React, { createContext, useEffect, useState, useMemo } from 'react'

import PropTypes from 'prop-types'
import useUserAuthStore, { ACTIONS } from '@/store/userAuthStore'
import Cookies from 'universal-cookie'
import useAuth from '@/hooks/useAuth'
import { useKeycloak } from '@react-keycloak/web'

const AuthContext = createContext()

function AuthContextProvider({ children }) {
  const { state, dispatch } = useUserAuthStore()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const cookies = new Cookies()
  const { initialized, keycloak } = useKeycloak()
  const { getCurrentUser } = useAuth()
  useEffect(() => {
    if (!initialized) return
    if (!keycloak.authenticated) {
      keycloak.login()
    }
    getCurrentUser().then((currentUser) => {
      setUser(currentUser)
      dispatch({ type: ACTIONS.setUser, payload: currentUser })
      dispatch({ type: ACTIONS.setToken, payload: cookies.get('accessToken') })

      setLoading(false)
      if (
        !currentUser
        && !currentUser?.platform?.skills
        && currentUser?.platform?.skills.toLowerCase() === 'na'
      ) {
        window.location.href = process.env.REACT_APP_INTRA_URL
      }
    })
  }, [initialized, keycloak])

  const contextValue = useMemo(() => ({ user,
    setUser,
    loading,
    dispatch,
    state
  }), [user, setUser, loading, dispatch, state])

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AuthContext, AuthContextProvider }
